document.addEventListener('DOMContentLoaded', (event) => {
  const acknowledgeCheckbox = document.getElementById('acknowledgeRules');
  const startQuizButton = document.getElementById('startQuizBtn');
  const confirmStartQuizButton = document.getElementById('confirmStartQuizBtn');
  const rulesModalElement = document.getElementById('rulesModal');
  const confirmationModalElement = document.getElementById('confirmationModal');
  const rulesModal = rulesModalElement ? new bootstrap.Modal(rulesModalElement) : null;
  const confirmationModal = confirmationModalElement ? new bootstrap.Modal(confirmationModalElement) : null;

  if (acknowledgeCheckbox && startQuizButton && confirmStartQuizButton && rulesModal && confirmationModal) {
    acknowledgeCheckbox.addEventListener('change', (e) => {
      startQuizButton.disabled = !e.target.checked;
    });

    startQuizButton.addEventListener('click', () => {
      if (acknowledgeCheckbox.checked) {
        // Close the rules modal before showing the confirmation modal
        rulesModal.hide();
        rulesModal._element.addEventListener('hidden.bs.modal', function onModalHidden() {
          confirmationModal.show();
          rulesModal._element.removeEventListener('hidden.bs.modal', onModalHidden);
        });
      }
    });

    confirmStartQuizButton.addEventListener('click', () => {
      // Assuming your form has the id 'startQuizForm'
      let startQuizForm = document.getElementById('startQuizForm');
      if (startQuizForm) {
        startQuizForm.submit();
      }
    });
  }
});
